/**
 * 正则工具类
 * 提供一系列正则表达式处理函数，用于格式化和校验字符串数据，
 * 包括限制小数位数、保留字母数字组合、过滤非数字等。
 * @author HuangJun
 */

/**
 * 清理并格式化数字字符串，仅保留数字和一个小数点，处理以小数点开头的情况。
 * @param {string} val - 待处理的字符串
 * @returns {string} 格式化后的数字字符串
 */
const onlyNumberAndPoint = val => {
  // 先清理非数字和多余的点
  let cleaned = val.replace(/[^0-9.]/g, '');
  // 处理以小数点开头的情况，补零
  if (cleaned.startsWith('.')) cleaned = '0' + cleaned;
  // 确保只有一个点
  return cleaned.replace(/(\..*)\./, '$1');
};

/**
 * 清理并格式化数字字符串，仅保留数字和一个小数点和负数，处理以小数点开头的情况。
 * @param {string} val - 待处理的字符串
 * @returns {string} 格式化后的数字字符串
 */
const onlyNumberAndPointAndNegative = val => {
  // 先清理非数字和多余的点
  let cleaned = val.replace(/[^0-9.-]/g, '');
  // 处理以小数点开头的情况，补零
  if (cleaned.startsWith('.')) cleaned = '0' + cleaned;
  // 确保只有一个点
  return cleaned.replace(/(\..*)\./, '$1');
};

/**
 * 限制数字字符串的小数位数。
 * @param {string} val - 待处理的数字字符串
 * @param {number} n - 限制的小数位数，默认为6
 * @returns {string} 限制小数位后的数字字符串
 */
export const keepNDecimal = (val, n = 6) => {
  const cleanedVal = onlyNumberAndPoint(val);
  const parts = cleanedVal.split('.');
  // 如果没有小数部分，直接返回整数部分
  if (parts.length === 1) return cleanedVal;
  // 有限制小数位时处理小数部分
  const decimalPart = parts[1].substring(0, n);
  return `${parts[0]}.${decimalPart}`;
};

/**
 * 限制数字字符串的小数位数包含负数。
 * @param {string} val - 待处理的数字字符串
 * @param {number} n - 限制的小数位数，默认为6
 * @returns {string} 限制小数位后的数字字符串
 */
export const keepNDecimalAndNegative = (val, n = 6) => {
  const cleanedVal = onlyNumberAndPointAndNegative(val);
  const parts = cleanedVal.split('.');
  // 如果没有小数部分，直接返回整数部分
  if (parts.length === 1) return cleanedVal;
  // 有限制小数位时处理小数部分
  const decimalPart = parts[1].substring(0, n);
  return `${parts[0]}.${decimalPart}`;
};

/**
 * 保留字符串中前两位字母（转大写）及之后的5位数字。
 * @param {string} val - 原始字符串
 * @returns {string} 格式化后的字符串
 */
export const keepLetter2AndNumber5 = val => {
  let prefixStr = val
    .substring(0, 2)
    ?.replace(/[^A-Za-z]/g, '')
    .toUpperCase();
  let subStr = val.substring(2, val.length)?.replace(/[^0-9]/g, '');
  return prefixStr + subStr;
};

/**
 * 如果只有一个负数则返回空
 * @param val
 * @returns {*|null}
 */
export const notAloneNegative = val => {
  if (val.length === 1 && val === '-') {
    return 0;
  } else {
    return val;
  }
};

// 导出不同小数位数限制的快捷方法
export const keep6Decimal = val => keepNDecimal(val, 6);
export const keep5Decimal = val => keepNDecimal(val, 5);
export const keep4Decimal = val => keepNDecimal(val, 4);
export const keep3Decimal = val => keepNDecimal(val, 3);
export const keep2Decimal = val => keepNDecimal(val, 2);
export const keep1Decimal = val => keepNDecimal(val, 1);
export const keep12Decimal = val => keepNDecimal(val, 12);

// 导出不同小数位数限制的快捷方法包含负数
export const keep2DecimalContainNegative = val => keepNDecimalAndNegative(val, 2);

//过滤只有一个负数时返回0
export const keepNotAloneNegative = val => notAloneNegative(val);

// 其他正则处理方法
export const keepNumber = val => val.replace(/\D/g, ''); // 仅保留数字
export const keepNumberAndSpecialCharactersAndSpaces = val => val.replace(/[^0-9\s!"#$%&'()*+,-./:;<=>?@[\\^_`{|}~]/g, ''); // 保留数字、空白及特殊字符
export const removeAroundSpaces = val => val.replace(/^\s+|\s+$/g, ''); // 移除首尾空白
export const keepEngAndNumUp = val => val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(); // 保留英文字母和数字，转大写
export const keepEngAndNumLow = val => val.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(); // 保留英文字母和数字，转小写
export const keepEngAndSpace = val => val.replace(/[^a-zA-Z0-9\s.,\-]/g, ''); // 保留英文字母、数字、空白及特定标点
export const removeChinese = val => val.replace(/[\u4e00-\u9fa5]/g, ''); // 去除中文
