import { getDateNoTime } from '@assets/js/dateUtils';

export const DeliProdList = [
  {
    label: '发单日期',
    prop: 'order_date',
    itemType: 'date',
    input: true,
    sortable: true,
    labelWidth: 180,
    valueFormat: 'timestamp',
    formatter: val => {
      return getDateNoTime(val, true);
    }
  },
  {
    label: '进仓发票号',
    prop: 'tran_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '采购经办人',
    prop: 'deli_stff_name',
    itemType: 'select',
    filterable: true,
    input: true,
    options: [],
    sortable: false,
    labelWidth: 180
  },
  {
    label: '要求进仓日期',
    prop: 'deli_date',
    itemType: 'date',
    input: true,
    sortable: false,
    labelWidth: 180,
    valueFormat: 'timestamp',
    formatter: val => {
      return getDateNoTime(val, true);
    }
  },
  {
    label: '订单号',
    prop: 'scon_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '中文品名',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  { label: '产品数量', prop: 'prod_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '箱数', prop: 'prod_box_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '箱规', prop: 'box_gauge', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '立方数', prop: 'cubic_number', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '仓库名称', prop: 'warehouse', itemType: 'input', input: true, sortable: false, labelWidth: 180 },
  {
    label: '备注',
    prop: 'deli_prod_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: false,
      type: 'popoverInput'
    }
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    fixed: 'right',
    labelWidth: 80
  }
];
