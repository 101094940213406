<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportDeliProd()" icon="el-icon-download"> 导出Excel </el-button>
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRangeByOrder"
          type="daterange"
          range-separator="至"
          start-placeholder="发单开始日期"
          end-placeholder="发单结束日期"
        />
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRangeBydeli"
          type="daterange"
          range-separator="至"
          start-placeholder="进仓开始日期"
          end-placeholder="进仓结束日期"
        />
        <el-button type="primary" size="small" class="vd_export" @click="getDeliProdList()">搜索</el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="partTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        :need-search="true"
        :need-check-box="false"
        @getTableData="getDeliProdList"
        :row-click="row => (partCurrentRow = row)"
      >
        <template v-slot:operation="scope">
          <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { get, getNoCatch, post } from '@api/request';
import { DeliProdList } from '@/views/TransportationManagement/DeliProdManage/DeliProd';
import { deliAPI } from '@api/modules/deli';
import { getStff } from '@api/public';

export default {
  name: 'DeliProdList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      otherForm: {
        minus: '',
        plus: ''
      },
      dateRangeByOrder: [],
      dateRangeBydeli: [],
      activeName: '1',
      partTableProperties: cloneDeep(DeliProdList),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      partCurrentRow: {}
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    keepNumber,
    async initData() {
      await this.getDeliProdList();
      this.partTableProperties.find(x => x.label === '采购经办人').options = await getStff('', 'default', '', 'stff_name');
    },
    //获取数据
    async getDeliProdList() {
      this.loadFlag = true;
      let searchForm = this.$refs['partMultiTable'].searchForm;
      let [order_date_start, order_date_end] = this.dateRangeByOrder || [];
      let [deli_date_start, deli_date_end] = this.dateRangeBydeli || [];
      searchForm.order_date_start = order_date_start ? order_date_start / 1000 : null;
      searchForm.order_date_end = order_date_end ? order_date_end / 1000 : null;
      searchForm.deli_date_start = deli_date_start ? deli_date_start / 1000 : null;
      searchForm.deli_date_end = deli_date_end ? deli_date_end / 1000 : null;
      await getNoCatch(deliAPI.getDeliProdList, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportDeliProd() {
      let searchForm = this.$refs['partMultiTable'].searchForm;
      get(deliAPI.exportDeliProd, searchForm).then(res => {
        if (res.data.code === 0) {
          this.excelUrl = this.helper.megPath(res.data.data.url);
          this.excelName = '进仓单汇总.xlsx';
          this.helper.downloadItemC(this.excelUrl, this.excelName);
          return this.$message({ message: '导出成功', type: 'success' });
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //刷新
    buttonRefresh() {
      this.$refs['partMultiTable'].resetFields();
      this.dateRangeByOrder = [];
      this.dateRangeBydeli = [];
      this.getDeliProdList();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    //保存
    async saveRow(row, index) {
      let { deli_prod_id, deli_prod_remark } = row;
      console.log(deli_prod_id, 2222222222, deli_prod_remark);
      post(deliAPI.editDeliProdRemarkByDeliProdId, row).then(res => {
        if (res.data.code === 0) {
          this.buttonRefresh();
          return this.$message({ message: '保存成功！', type: 'success' });
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
